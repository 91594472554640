.expired-payment-header-content {
  border: none !important;
  margin-bottom: 45px;
  line-height: 20px !important;
}

.payment-header {
  margin-bottom: 0 !important;
  border: none !important;
  padding-bottom: 0 !important;
}

.btn__grey {
  max-width: 100%;
  width: 329px;
  margin: auto !important;
  margin-bottom: 15px;
  display: block;
}

.error-header__warning {
  margin: 100px auto 49px !important;
}
